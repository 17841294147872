import { LyDialog } from "@alyle/ui/dialog";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectorRef, Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, isDevMode } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CropperComponent } from "@fuse/Dialog/cropper/cropper.component";
import { fuseAnimations } from "@fuse/animations";
import { CommonService } from "@fuse/services/common.service";
import { ToolbarService } from "app/layout/components/toolbar/toolbar.service";
import { NgxFileDropEntry } from "ngx-file-drop";
import { CertificateToolService } from "./certificate-tool.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Validators } from "ngx-editor";
import { MatDialog } from "@angular/material/dialog";
import { CommonMessageSubmitComponent } from "@fuse/Dialog/common-message-submit/common-message-submit.component";
import { Router } from "@angular/router";
import { CertificateUpdateService } from "./certificate-update.service";
import { ConfirmationDialogComponent } from "@fuse/Dialog/confirmation-dialog/confirmation-dialog.component";

@Component({
    selector: "app-create-certificate",
    templateUrl: "./create-certificate.component.html",
    styleUrls: ["./create-certificate.component.scss"],
    animations: [
            trigger('slideInOut', [
            state('in', style({
              right: 0,
            })),
            state('out', style({
              right: '-250px',
            })),
            transition('in => out', animate('300ms ease-out')),
            transition('out => in', animate('300ms ease-in')),
            ]),
            trigger('showHide', [
			transition(':enter',
				[
					style({ transform: 'translateY(-20px)', opacity: 0 }),
					animate(200, style({ transform: 'translateY(0px)', opacity: 1 })),
				]),
			transition(':leave',
				[
					animate(200, style({ transform: 'translateY(-20px)', opacity: 0 })),
				]),
            ]),
        fuseAnimations
    ]
})
export class CreateCertificateComponent implements OnInit, OnDestroy {
    // templateBg: string = "";
    showSideBar:boolean = false;
    selectedEleId = null;
    parentElement: string = '';
    clickTimer;
    form:FormGroup;

    backgrounds:[] = [];
    certificate = 'certificate'

    public menuState = 'out'; 

    toggleMenu() {
        this.menuState = this.menuState === 'out' ? 'in' : 'out';
    }
    
    constructor(
        private _ToolbarService: ToolbarService,
        private _snackBar: MatSnackBar,
        private _router : Router,
        private _matDialog : MatDialog,
        private _fb : FormBuilder,
        private _CertificateUpdateService : CertificateUpdateService,
        public _certificateToolService : CertificateToolService,
        private _dialog: LyDialog,
        private _commonService: CommonService
    ) {
        if(this._CertificateUpdateService.templateDetails){
            this.setToolBarTitle(true);
            this.createForm(this._CertificateUpdateService.templateDetails);
            this._certificateToolService.templateData = this._CertificateUpdateService.templateDetails.layout;
            console.log("this._CertificateUpdateService.templateDetails.layout", this._CertificateUpdateService.templateDetails.layout);
            console.log("this._certificateToolService.templateData", this._certificateToolService.templateData);
            
        }else{
            this.setToolBarTitle(false);
            this.createForm();
        }
        this.getAllBackgroundImages()
    }
   

    ngOnInit(): void {}

    @HostListener('document:keydown', ['$event']) onClickEvent(event: any) {
        let keys = event.keyCode;
        if (this.selectedEleId) {
          switch (keys) {
            case 37:
              this.leftClick()
              break;
            case 38:
              this.upClick()
              break;
            case 39:
              this.rightClick()
              break;
            case 40:
              this.downClick()
              break;
          }
        }
      }

      

    setToolBarTitle(edit) {
        if (edit) {
            // for title
            this._ToolbarService._ToolBarTitle.next("Update Certificate");
            // for btn
            this._ToolbarService._isBackBtnShow.next(true);
        } else {
            // for title
            this._ToolbarService._ToolBarTitle.next("Create Certificate");
            // for btn
            this._ToolbarService._isBackBtnShow.next(false);
        }
    }

    createForm(data?) {
        this.form = this._fb.group({
            templateData : [data || '', Validators.required],
            title : [data?.name || '', Validators.required],
            thumbnailImage : ['', Validators.required]
        })
    }

    openCropperForBg(event, isFor?) {
        console.log("isFor", event.target.files[0]);
        // console.log("blob", new Blob(event.target.files[0]));
        // this._dialog.open<CropperComponent, any>(CropperComponent, {
        //         data: {
        //             fileEvent: event,
        //             myConfig: {
        //                 width: 3508,
        //                 height: 2481,
        //                 round: false,
        //                 type: "image/png",
        //                 keepAspectRatio: true,
        //                 responsiveArea: true,
        //                 output: {
        //                     width: 3508,
        //                     height: 2481,
        //                 },
        //                 resizableArea: true,
        //             },
        //         },
        //         width: 520,
        //         height : 520,
        //         disableClose: true,
        //     })
            // .afterClosed.subscribe((result: any) => {
                // if (result) {
                    this.onFileUpload(event.target.files[0], event.target.files[0], isFor);
            //     }
            // });
    }

    openCropperForProfile(event){
        this._dialog.open<CropperComponent, any>(CropperComponent, {
            data: {
                fileEvent: event,
                myConfig: {
                    width: 300,
                    height: 168,
                    round: false,
                    type: "image/png",
                    keepAspectRatio: true,
                    responsiveArea: true,
                    output: {
                        width: 300,
                        height: 168,
                    },
                    resizableArea: true,
                },
            },
            width: 520,
            height : 520,
            disableClose: true,
        })
        .afterClosed.subscribe((result: any) => {
            if (result) {
                this.onFileUpload(result.file, result.blobFile, 'signature')
            }
        });
    }

    onFileUpload(fileObj, blobFile, isFor) {
        console.log("isFor", isFor);
        // const payload = {
        //     "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
        //     "fileName": isFor == 'thumbnail' ? 'thumbnail_' :'background_',
        //     "folderName": isFor == 'thumbnail' ? 'certificate/thumbnail/image': 'ceritificate/layout/backgroundImage', // it is fix set by backend.
        //     'mimeType': fileObj?.type
        // }
        const payload = {
            "fileExt": `.${String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1)}`,
            "fileName": `${isFor == 'thumbnail' ? 'thumbnail_' :'background_'}`,
            "folderName": `${isFor == 'thumbnail' ? 'certificate/thumbnail/image': 'ceritificate/layout/backgroundImage'}`, // it is fix set by backend.
            'mimeType': `${fileObj?.type}`
        }
        console.log("payload", payload);
        this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
            if (result.status == 200) {
              
                // payload for get Image upload status
                const payload = {
                    'id': result.data.id,
                    'status': 1 // fix by backend. 
                }
                this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj.type, blobFile).then((res)=>{
                    if(res){
                        console.log("your file is uploaded here", res);
                        // this.templateBg  = result.data.imageUploadUrl;
                        if(isFor == 'signature'){
                            this._certificateToolService.templateData.signature['link'] = result.data.imageUploadUrl;
                        }
                        else if(isFor == 'thumbnail'){
                            this.form.get('thumbnailImage').setValue(result.data.imageUploadUrl);
                            console.log("this.form", this.form);
                            
                        }
                        else if(isFor == 'bg'){
                            this._certificateToolService.templateData.backgroundImage = result.data.imageUploadUrl;
                            this.addCertificateImage(result.data.imageUploadUrl)
                        }
                    }
                })

                this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                    if (uploadStatusResponse.status == 200) {
                        this._snackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                    } else {
                        this._snackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                    }
                })
            }
        })
    }

    addCertificateImage(url){
        this._commonService.addCertificateImage({imageUrl : url}).then((res:any)=>{
            if(res.status == 200){
                this.getAllBackgroundImages();
            }
        })
    }

    getAllBackgroundImages(){
        this._commonService.getAllCertificateBackgroundImage().then((res:any)=>{
            if(res.status == 200){
                this._certificateToolService.backgrounds =  res.data;
            }else{
                this._certificateToolService.backgrounds =  [];
            }
        })
    }

    onSelectBg(i){ 
        this._certificateToolService.backgrounds.forEach((item:any, j) => {
          if(j == i){
            item.isSelected = 1;
            this._certificateToolService.templateData.backgroundImage = item.imageUrl;
          }else{
            item.isSelected = 0; 
          }
        })
 
      }


      onDeleteBG(image){
        let dialog = this._matDialog.open(ConfirmationDialogComponent, {
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          disableClose: false,
          data : {
              message : 'Are you sure you want to remove this ?',
              image : '/assets/ui/delete.png',
              focus : 'right',
              deleteItem : 'Remove Background?',
              confirmButtonText : 'Yes',
              cancelButtonText : 'No'
          }
        })
      // handle after close
        dialog.afterClosed().subscribe((res) => {
          if(res){
            this._commonService.certificateDeleteBgImage({id : image.id}).then((result:any)=>{
              if(result.status == 200){
                this.getAllBackgroundImages();
                // check if deleteable image is selected then change the selected image
                if(image.isSelected){
                  this._certificateToolService.templateData.backgroundImage = '';
                }
              }
              this._snackBar.open(result.message, "OKAY", {duration : 2000})
            })
          }
        })
    }

    isFileAllowed(fileName: string) {
        let isFileAllowed = false;
        const allowedFiles = [".jpeg", ".jpg", ".png", ".pdf"];
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(fileName);

        if (isDevMode()) {
            console.log("extension du fichier : ", extension);
        }

        if (undefined !== extension && null !== extension) {
            for (const ext of allowedFiles) {
                if (ext === extension[0]) {
                    isFileAllowed = true;
                }
            }
        }

        return isFileAllowed;
    }

    showElements(){

    }


    onAddElement(){
        const tempField = JSON.parse(JSON.stringify(this._certificateToolService.newDynamicElement))
        tempField.text = 'Enter Text Here';
        tempField.fieldName = '';
        tempField.contentEditable = true;
        
        if(this._certificateToolService.templateData.dynamicElements.length != 0)
          tempField.position.top = (this._certificateToolService.templateData.dynamicElements.length * 8);
        else
          tempField.position.top = 5

        this._certificateToolService.templateData.dynamicElements.push(tempField);
        console.log("this._certificateToolService.templateData.dynamicElements", this._certificateToolService.templateData.dynamicElements);
    }

    onElementClick(event, eleType?, item?) {
        console.log("item", item);
        console.log("eleType", eleType);
          if (eleType) {
            this.parentElement = eleType;
          } else {
            this.parentElement = '';
          }
          this.selectedEleId = '';
          this.selectedEleId = event.target.id;
          console.log("event.target.id", event.target.id);
          console.log("parentElement", this.parentElement);
          
        // if(event.target.id != 'certificate' && (!item?.isContentEditing || item?.link)){
        //     this.dragElement(document.getElementById(event.target.id), item)
        // }
    }

    onPrint(){
        console.log("template data", this._certificateToolService.templateData);
    }
    
    dragElement(elmnt, item) {
        console.log("dragElement", item);
        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

            if (document.getElementById(elmnt.id + "header")) {
                /* if present, the header is where you move the DIV from:*/
                document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
            } else {
                /* otherwise, move the DIV from anywhere inside the DIV:*/
                elmnt.onmousedown = dragMouseDown;
            }

            function dragMouseDown(e) {
                e = e || window.event;
                // e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                document.onmouseup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
            }
    
            function elementDrag(e) {
                e = e || window.event;
                // e.preventDefault();
                // calculate the new cursor position:
                pos1 = pos3 - e.clientX;
                console.log("e.clientX", e.clientX);
                console.log("pos1", pos1);
                
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;
                // set the element's new position:

                    // elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                    // elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
                    elmnt.style.top = ((e.clientX) / 608) * 100;
                    console.log("elmnt.offsetTop -", ((elmnt.offsetTop - pos2) / 608) * 100);
                    elmnt.style.left = ((elmnt.offsetTop - pos2) / 868) * 100;
                    console.log("elmnt.style.top", elmnt.style.top);
                    
                    item.position.top = elmnt.style.top;
                    item.position.left =  elmnt.style.left;
                    
            }   
    
            function closeDragElement() {
                /* stop moving when mouse button is released:*/
                document.onmouseup = null;
                document.onmousemove = null;
            }
        

    }
    

    onEditContent(item){
        console.log("item --- ", item);
        this.openTextDailog(item)
    }

    openTextDailog(item){
        const dialog = this._matDialog.open(CommonMessageSubmitComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: true,
            width : '380px',
            autoFocus : true,
            data : {
                title : `Add Custom Text`,
                label : 'Text',
                confirmButtonText : 'Save',
                errorMsg : 'Text is required.',
                text : item.text
            }
        })
        dialog.afterClosed().subscribe((res)=>{
            if(res)
                item.text = res;
        })
    }

    
    onShowHideEle(isShow) {
        if (this.parentElement == '')
        this._certificateToolService.templateData[this.selectedEleId].display = isShow
        else
        this._certificateToolService.templateData[this.parentElement][this.selectedEleId].display = isShow
    }

    globalAlign(val) {
        let selectedElement
        if (this.parentElement == '') {
          selectedElement = this._certificateToolService.templateData[this.selectedEleId]
        }
        else {
          selectedElement = this._certificateToolService.templateData[this.parentElement][parseInt(this.selectedEleId)]
        }
    
        if (val == 'hl') {
          selectedElement.position.left = 0
        }
        else if (val == 'hc') {
          selectedElement.position.left = 50
        }
        else if (val == 'hr') {
          selectedElement.position.left = 100
        }
    
        else if (val == 'vt') {
          selectedElement.position.top = 0
        }
        else if (val == 'vc') {
          selectedElement.position.top = 50
        }
        else if (val == 'vb') {
          selectedElement.position.top = 100
        }
      }
    
    mouseup() {
        clearInterval(this.clickTimer);
    }

    mousedown(key) {
        if (key == 'lc') {
            this.clickTimer = setInterval(() => {
                this.leftClick()
            }, 125)
        }
        else if (key == 'rc') {
            this.clickTimer = setInterval(() => {
                this.rightClick()
            }, 125)
        }
        else if (key == 'uc') {
            this.clickTimer = setInterval(() => {
                this.upClick()
            }, 125)
        }
        else if (key == 'dc') {
            this.clickTimer = setInterval(() => {
                this.downClick()
            }, 125)
        }
        else if (key == 'rr') {
            this.clickTimer = setInterval(() => {
                this.rotateRight()
            }, 125)
        }
        else if (key == 'rl') {
            this.clickTimer = setInterval(() => {
                this.rotateLeft()
            }, 125)
        }
    }

    leftClick() {
        if (!(this.selectedEleId == 'ticket')) {
            let selectedElement;
            if (this.parentElement == '') { selectedElement = this._certificateToolService.templateData[this.selectedEleId] }
            else { selectedElement = this._certificateToolService.templateData[this.parentElement][parseInt(this.selectedEleId)] } 
            if (selectedElement?.position?.left > 0) selectedElement.position.left -= 0.1;
        }
    }

    rightClick() {
        if (!(this.selectedEleId == 'ticket')) {
            let selectedElement
            if (this.parentElement == '') { selectedElement = this._certificateToolService.templateData[this.selectedEleId] }
            else { selectedElement = this._certificateToolService.templateData[this.parentElement][parseInt(this.selectedEleId)] } 
            if (selectedElement.position.left < Number(this._certificateToolService.defaultTemplateFormat.width - selectedElement.width))
            selectedElement.position.left += 0.1;
        }
    }

    upClick() {
        if (!(this.selectedEleId == 'ticket')) {
            let selectedElement
            if (this.parentElement == '') { selectedElement = this._certificateToolService.templateData[this.selectedEleId] }
            else { selectedElement = this._certificateToolService.templateData[this.parentElement][parseInt(this.selectedEleId)] } 
            if (selectedElement.position.top > 0) selectedElement.position.top -= 0.1;
        }
    }

    downClick() {
        if (!(this.selectedEleId == 'ticket')) {
            let selectedElement
            if (this.parentElement == '') { selectedElement = this._certificateToolService.templateData[this.selectedEleId] }
            else { selectedElement = this._certificateToolService.templateData[this.parentElement][parseInt(this.selectedEleId)] } 
            if (selectedElement.position.top < 310)
            selectedElement.position.top += 0.1;
        }
    }

    rotateRight() {
        this.clickTimer = setInterval(() => {
            let selectedElement
            if (this.parentElement == '') {
                selectedElement = this._certificateToolService.templateData[this.selectedEleId]
            }
            else {
                selectedElement = this._certificateToolService.templateData[this.parentElement][parseInt(this.selectedEleId)]
            }
                selectedElement.rotate++
        }, 1)
    }

    rotateLeft() {
        this.clickTimer = setInterval(() => {
          let selectedElement
          if (this.parentElement == '') {
            selectedElement = this._certificateToolService.templateData[this.selectedEleId];
          }
          else {
            selectedElement = this._certificateToolService.templateData[this.parentElement][parseInt(this.selectedEleId)];
          }
          selectedElement.rotate--
        }, 1)
    }

    changeFont(opreators) {
        if (this._certificateToolService.templateData.dynamicElements[this.selectedEleId].fontSize > 8 && opreators === '-') {
          this.parentElement == '' ? this._certificateToolService.templateData.dynamicElements[this.selectedEleId].fontSize-- : this._certificateToolService.templateData[this.parentElement][this.selectedEleId].fontSize--;
        } else if (this._certificateToolService.templateData.dynamicElements[this.selectedEleId].fontSize < 150 && opreators === '+') {
          this.parentElement == '' ? this._certificateToolService.templateData.dynamicElements[this.selectedEleId].fontSize++ : this._certificateToolService.templateData[this.parentElement][this.selectedEleId].fontSize++;
        }
    }

    setFontColor(color) {
        this.parentElement == '' ? (this._certificateToolService.templateData.dynamicElements[this.selectedEleId].fontColor = color) : (this._certificateToolService.templateData[this.parentElement][this.selectedEleId].fontColor = color)
    }

    onBoldClick() {
        if (this.parentElement == '') {
          this._certificateToolService.templateData.dynamicElements[this.selectedEleId].bold = !this._certificateToolService.templateData.dynamicElements[this.selectedEleId].bold
        } else
          this._certificateToolService.templateData[this.parentElement][this.selectedEleId].bold = !this._certificateToolService.templateData[this.parentElement][this.selectedEleId].bold
      }
    
    onItalicClick() {
    if (this.parentElement == '') {
        this._certificateToolService.templateData.dynamicElements[this.selectedEleId].italic = !this._certificateToolService.templateData.dynamicElements[this.selectedEleId].italic
    } else
        this._certificateToolService.templateData[this.parentElement][this.selectedEleId].italic = !this._certificateToolService.templateData[this.parentElement][this.selectedEleId].italic
    }

    onUnderlineClick() {
    if (this.parentElement == '') {
        this._certificateToolService.templateData.dynamicElements[this.selectedEleId].underline = !this._certificateToolService.templateData.dynamicElements[this.selectedEleId].underline
    } else
        this._certificateToolService.templateData[this.parentElement][this.selectedEleId].underline = !this._certificateToolService.templateData[this.parentElement][this.selectedEleId].underline
    }

    localAlign(value) {
        if(!value) return;
        
        if (this.parentElement == '') {
            this._certificateToolService.templateData.dynamicElements[this.selectedEleId].align = value
        }
        else {
            this._certificateToolService.templateData[this.parentElement][parseInt(this.selectedEleId)].align = value
        }
    }

    onSaveCerificate(){
        console.log("this.form.get('thumbnailImage').value", this.form); 
        
        this._certificateToolService.templateData.dynamicElements.map((element) => {  
            if(element.position.top){
                if(String(element.position.top).endsWith('px')){
                    element.position.top = String(element.position.top).slice(0, String(element.position.top).length - 2)
                }
            }
            if(element.position.left){
                if(String(element.position.left).endsWith('px')){
                    element.position.left = String(element.position.left).slice(0, String(element.position.left).length - 2)
                }
            }
        })

        if(this._certificateToolService.templateData?.signature?.position?.top){
            if(String(this._certificateToolService.templateData.signature.position.top).endsWith('px')){
                this._certificateToolService.templateData.signature.position.top = Number(String(this._certificateToolService.templateData.signature.position.top).slice(0, String(this._certificateToolService.templateData.signature.position.top).length - 2))
            }
        }

        if(this._certificateToolService.templateData?.signature?.position?.left){
            if(String(this._certificateToolService.templateData.signature.position.left).endsWith('px')){
                this._certificateToolService.templateData.signature.position.left = Number(String(this._certificateToolService.templateData.signature.position.left).slice(0, String(this._certificateToolService.templateData.signature.position.left).length - 2))
            }
        }


        if(this._CertificateUpdateService.templateDetails){
            const payload = {
                id : this._CertificateUpdateService.templateDetails.id,
                name : this.form.get('title').value,
                backgroundImage : this._certificateToolService.templateData.backgroundImage,
                layout : this._certificateToolService.templateData,
                thumbnailImage : this.form.get('thumbnailImage').value
            }
            console.log("final payload", payload);

            this._commonService.onUpdateCertificate(payload).then((res:any)=>{
                if(res.status == 200){
                    this._snackBar.open(res.message, 'OKAY', {duration : 2000});
                    this._router.navigate(['/certificate/templates'])
                }else{
                    this._snackBar.open(res.message, 'OKAY', {duration : 3000});
                }
            })

        }else{
            const payload = {
                name : this.form.get('title').value,
                backgroundImage : this._certificateToolService.templateData.backgroundImage,
                layout : this._certificateToolService.templateData,
                thumbnailImage : this.form.get('thumbnailImage').value
            }
            console.log("payload", payload);
            this._commonService.onAddCertificate(payload).then((res:any)=>{
                if(res.status == 200){
                    this._snackBar.open(res.message, 'OKAY', {duration : 2000});
                    this._router.navigate(['/certificate/templates'])
                }else{
                    this._snackBar.open(res.message, 'OKAY', {duration : 3000});
                }
            })
        }


    }


    onDeleteElement(item){
        const index = this._certificateToolService.templateData.dynamicElements.findIndex(element => element == item);
        this._certificateToolService.templateData.dynamicElements.splice(index, 1);
        this.selectedEleId = '';
        this.parentElement = '';
    }

    ngOnDestroy(): void {
        this._certificateToolService.templateData = {
            backgroundImage : '',
            dynamicElements : [],
            fontFamilyCdn:'https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Bilbo&family=Dancing+Script:wght@400;500;600;700&family=Fleur+De+Leah&family=Kaushan+Script&family=Playball&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Qwigley&family=Roboto:wght@100;300;400;500;700;900&family=Sail&family=Sansita+Swashed:wght@300;500;700;900&family=Six+Caps&family=Tangerine:wght@400;700&display=swap',
            signature: {
              link: '',
              width: 100,
              display: true,
              opacity: 1,
              rotate: 0,
              position: {
                top: 31.99,
                left: 90.5
              }
            },
            width : 868,
            height : 608,  
        }

        this._certificateToolService.defaultTemplateFormat = {
            backgroundImage : '',
            dynamicElements : [],
            width : 868,
            height : 608, 
            signature: {
              link: '',
              width: 100,
              display: true,
              opacity: 1,
              rotate: 0,
              position: {
                top: 31.99,
                left: 90.5
              }
            },
        }

        this._CertificateUpdateService.templateDetails = null;
    }

    onRemoveSignature(){
        this._certificateToolService.templateData.signature = { link: '', width: 100, display: true, opacity: 1, rotate: 0, position: { top: 31.99, left: 90.5}}
        this.selectedEleId = '';
    }

}
